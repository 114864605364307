import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import MakerSaleTable from "components/makerSaleTableLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import ScrollTable from "components/scrollTable";
import SalesTweetList from "components/salesTweetListLoadable";
import { graphql } from "gatsby";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "pc-sales"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/rank/pc-sale.png"}) { ...eyecatchImg },
    cwhere: file(relativePath: { eq: "cover/others/where.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`パソコンセール情報まとめ`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="パソコンセール情報まとめ" mdxType="Image" />
    <p>{`本ページでは、パソコンをネットで安く購入できるようセール、アウトレット、クーポンの情報を一覧にしてまとめている。`}</p>
    <p>{`これらのまとめ情報を参考に、自分にとって必要なパソコンをできる限り安い価格で手に入れて欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "パソコンセール情報・アウトレット・クーポン情報",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%BB%E3%83%BC%E3%83%AB%E6%83%85%E5%A0%B1%E3%83%BB%E3%82%A2%E3%82%A6%E3%83%88%E3%83%AC%E3%83%83%E3%83%88%E3%83%BB%E3%82%AF%E3%83%BC%E3%83%9D%E3%83%B3%E6%83%85%E5%A0%B1",
        "aria-label": "パソコンセール情報・アウトレット・クーポン情報 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンセール情報・アウトレット・クーポン情報`}</h2>
    <p>{`パソコンの直販サイトで行なっているセールページやアウトレットへのリンクをまとめています。順番は`}<strong parentName="p"><em parentName="strong">{`当サイトでの人気が高い順`}</em></strong>{`で並べているので、特にメーカーが決まっていなければ上から見ていけば良いだろう。`}</p>
    <p>{`セール品はものやメーカーにもよるが、`}<strong parentName="p"><em parentName="strong">{`10万円の商品であるならば、5%程度安い9万5000円で購入できるぐらいが値引きの相場`}</em></strong>{`である。大還元セールなど大それた宣伝の割にはもとの価格とそれほど変わらなかったりするのだが、ともあれセール品が通常価格と比べて安いことには変わりはないので目的のパソコンがないか確認していこう。`}</p>
    <MakerSaleTable mdxType="MakerSaleTable" />
    <h3 {...{
      "id": "セール品は週一程度の頻度でチェックせよ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%BB%E3%83%BC%E3%83%AB%E5%93%81%E3%81%AF%E9%80%B1%E4%B8%80%E7%A8%8B%E5%BA%A6%E3%81%AE%E9%A0%BB%E5%BA%A6%E3%81%A7%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%81%9B%E3%82%88",
        "aria-label": "セール品は週一程度の頻度でチェックせよ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`セール品は週一程度の頻度でチェックせよ`}</h3>
    <p>{`スーパーの特売と同様、どの直販サイトも常にセールを行なっているが、重要なのはセール品が頻繁に入れ替わるということである。周期としては１週間でセール品を入れ替えというパターンが全体的には多い印象を受けるため、狙っている商品が安くなっていないかを週末チェックするのも良いだろう。`}</p>
    <h2 {...{
      "id": "お得にパソコンを購入する情報",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E5%BE%97%E3%81%AB%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E8%B3%BC%E5%85%A5%E3%81%99%E3%82%8B%E6%83%85%E5%A0%B1",
        "aria-label": "お得にパソコンを購入する情報 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`お得にパソコンを購入する情報`}</h2>
    <p>{`その他、パソコンをお得に購入するための時期と場所について簡単に述べたい。`}</p>
    <h3 {...{
      "id": "パソコンを購入する時期は？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E8%B3%BC%E5%85%A5%E3%81%99%E3%82%8B%E6%99%82%E6%9C%9F%E3%81%AF%EF%BC%9F",
        "aria-label": "パソコンを購入する時期は？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンを購入する時期は？`}</h3>
    <p>{`パソコンがお得に購入できる時期としては`}<strong parentName="p"><em parentName="strong">{`決算時期の３月と年末年始が狙い目のタイミング`}</em></strong>{`である。`}</p>
    <p>{`検討者の購買意欲が高まる時期は、どのメーカーも大量にパソコンを販売しようと狙いセールを開催する。パソコン一つ当たりの儲けは減るが、他メーカーに競り勝ち、売り上げ増によりトータルで利益を得ようとするのである。皆が買いたい時ほど安く買えるのである。`}</p>
    <p>{`特に年末年始は何か始めようと購入する人が多いのが特徴であり、不要不急の人にセールで煽ってパソコンを販売するため最も価格が安くなる印象がある。`}</p>
    <p>{`ただ、日本も`}<strong parentName="p"><em parentName="strong">{`インフレの時代に突入したため、思った時に購入するのが良い`}</em></strong>{`だろう。年末年始や決算時期がすぐならば待っても良いが、待つほどお得ということは滅多にない。`}</p>
    <h3 {...{
      "id": "どこで購入するのがお買い得？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%A9%E3%81%93%E3%81%A7%E8%B3%BC%E5%85%A5%E3%81%99%E3%82%8B%E3%81%AE%E3%81%8C%E3%81%8A%E8%B2%B7%E3%81%84%E5%BE%97%EF%BC%9F",
        "aria-label": "どこで購入するのがお買い得？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`どこで購入するのがお買い得？`}</h3>
    <p>{`AppleとMicrosoftは5%のポイントが付く量販店、NEC/富士通/東芝はオンラインストアか量販店(つまりどちらも大差がない)、その他はオンラインストアで購入すると安く買える可能性は高い。`}</p>
    <p>{`とはいえ量販店までわざわざ足を運ぶのも面倒であり、セール品が何かは行ってみなければ分からないという難点がある。オンラインストアはチェックしておきたいところである。`}</p>
    <p>{`詳しくは下記を参考にしてほしい。`}</p>
    <Card {...props} title="パソコンはどこで買うべきか？" description="パソコンを買うならどこがおすすめかをメーカーごとに、販売価格や品揃え、保証などを踏まえて解説。" image="cwhere" path="/where/" mdxType="Card" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      